<template>
  <div v-loading="isLoading" class="scene-slide-right">
    <div>
      <el-row
        v-if="similarExamList.length > 0"
        type="flex"
        justify="space-between"
        align="middle"
      >
        <el-row type="flex" align="middle" class="similar-title">
          <i class="el-icon-warning warn-tips" />
          <span class="red-text">发现题目高度相似，是否替换？</span>
        </el-row>
      </el-row>
      <div
        v-for="(item, index) in similarExamList"
        :key="item.examId"
        class="similar-exam-wrapper"
      >
        <div v-if="index === showIndex">
          <exam-item :item="item" :index="index + 1" />
          <div class="similar-bottom">
            <el-row type="flex" justify="space-between">
              <div class="red-text">相似度：{{ item.similar || 0 }}%</div>
              <div class="change-exam" @click="changeNextExam">
                <i class="el-icon-refresh" />
                换一个
              </div>
            </el-row>
            <el-row type="flex" justify="space-between">
              <el-button
                plain
                class="similar-btn"
                type="primary"
                @click="onSave(currentExamItem)"
              >
                不用替换
              </el-button>
              <el-button
                type="primary"
                class="similar-btn"
                @click="onReplaceExam(item)"
              >
                一键替换
              </el-button>
            </el-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ExamItem from '@/bizComponents/ExamItem'
export default {
  name: '',
  components: {
    ExamItem
  },
  props: {
    similarExamList: {
      type: Array,
      default: () => []
    },
    currentExamItem: {
      type: Object,
      default: () => {}
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    showIndex: {
      type: Number,
      default: 0
    }
  },
  methods: {
    changeNextExam() {
      this.$emit('changeNextExam')
    },
    onReplaceExam(item) {
      this.$emit('onReplaceExam', item)
    },
    onSave(item) {
      this.$emit('onSave', item)
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.scene-slide-right {
  width: 16vw;
  background-color: #fff;
  margin-left: 10px;
  overflow-y: auto;
  .tag {
    min-width: 80px;
  }
}
.similar-title {
  padding: 10px;
  background: #ffdbda;
  width: 100%;
}
.red-text {
  color: #e8312f;
  font-weight: bold;
}
.warn-tips {
  font-size: 20px;
  margin-right: 4px;
  color: rgba(246, 98, 94, 1);
}
.similar-bottom {
  padding: 12px;
  .el-row {
    padding-top: 10px;
    .similar-btn {
      width: 150px;
    }
  }
}
.change-exam {
  cursor: pointer;
  color: #a3a3a3;
}
.similar-exam-wrapper {
  padding-bottom: 20px;
}
.exam-item {
  padding-top: 8px;
  padding-bottom: 8px;
  color: #272e40;
  font-size: 14px;
  .color-info {
    padding-left: 50px;
  }
  .el-icon-delete {
    color: #e1e2e6;
    font-size: 20px;
    cursor: pointer;
  }
}
</style>
