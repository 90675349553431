<template>
  <div ref="scrollNode" class="scene-layout">
    <!-- 问卷题目 -->
    <!-- 题目类型 0：单选，1：多选，2：填空，3：问答，5：排序，6：量表 -->
    <div
      v-for="(item, index) in newExamList"
      :key="item.examId || item.id || index"
      class="scene-exam-wrapper"
    >
      <exam-simple
        v-if="item.answerType === 'single' || item.answerType === 'multiple'"
        :index="currentIndex()(item.id)"
        :item="item"
        :is-need="isNeed"
        :show-jump="showJump"
        :options-list="optionsList"
        :suitable-type="suitableType"
        @onSave="onSave"
        @onVideoPreview="onShowVideoPreviewModal"
        @onEditExamResource="onShowExamResourceModal"
        @onTigger="onTigger"
        @onTiggerTitle="onTiggerTitle"
        @onDel="onDel(item.id, item)"
        @onValid="onValidExamJump"
        @onAddExam="type => onAddExam(type, index + 1)"
        @onMoveUp="onMoveUp(index, index < 1, item)"
        @onMoveDown="onMoveDown(index, index === newExamList.length - 1, item)"
        @onJump="onJump"
      />
      <exam-sort
        v-if="item.answerType === 'sort'"
        :index="currentIndex()(item.id)"
        :item="item"
        :is-need="isNeed"
        :show-jump="showJump"
        :options-list="optionsList"
        :suitable-type="suitableType"
        @onSave="onSave"
        @onVideoPreview="onShowVideoPreviewModal"
        @onEditExamResource="onShowExamResourceModal"
        @onValid="onValidExamJump"
        @onTigger="onTigger"
        @onTiggerTitle="onTiggerTitle"
        @onDel="onDel(item.id, item)"
        @onAddExam="type => onAddExam(type, index + 1)"
        @onMoveUp="onMoveUp(index, index < 1, item)"
        @onMoveDown="onMoveDown(index, index === newExamList.length - 1, item)"
        @onJump="onJump"
      />
      <exam-fill
        v-if="item.answerType === 'text_fill'"
        :index="currentIndex()(item.id)"
        :item="item"
        :is-need="isNeed"
        :show-jump="showJump"
        :options-list="optionsList"
        :suitable-type="suitableType"
        @onSave="onSave"
        @onEditExamResource="onShowExamResourceModal"
        @onVideoPreview="onShowVideoPreviewModal"
        @onTigger="onTigger"
        @onTiggerTitle="onTiggerTitle"
        @onDel="onDel(item.id, item)"
        @onAddExam="type => onAddExam(type, index + 1)"
        @onValid="onValidExamJump"
        @onMoveUp="onMoveUp(index, index < 1, item)"
        @onMoveDown="onMoveDown(index, index === newExamList.length - 1, item)"
        @onJump="onJump"
      />
      <exam-blank-fill
        v-if="item.answerType === 'blank_fill'"
        :index="currentIndex()(item.id)"
        :item="item"
        :is-need="isNeed"
        :show-jump="showJump"
        :options-list="optionsList"
        :suitable-type="suitableType"
        @onSave="onSave"
        @onVideoPreview="onShowVideoPreviewModal"
        @onEditExamResource="onShowExamResourceModal"
        @onValid="onValidExamJump"
        @onTigger="onTigger"
        @onTiggerTitle="onTiggerTitle"
        @onDel="onDel(item.id, item)"
        @onAddExam="type => onAddExam(type, index + 1)"
        @onMoveUp="onMoveUp(index, index < 1, item)"
        @onMoveDown="onMoveDown(index, index === newExamList.length - 1, item)"
        @onJump="onJump"
      />
      <exam-scale
        v-if="item.answerType === 'scale'"
        :index="currentIndex()(item.id)"
        :item="item"
        :is-need="isNeed"
        :show-jump="showJump"
        :options-list="optionsList"
        :suitable-type="suitableType"
        @onSave="onSave"
        @onVideoPreview="onShowVideoPreviewModal"
        @onEditExamResource="onShowExamResourceModal"
        @onValid="onValidExamJump"
        @onTigger="onTigger"
        @onTiggerTitle="onTiggerTitle"
        @onDel="onDel(item.id, item)"
        @onAddExam="type => onAddExam(type, index + 1)"
        @onMoveUp="onMoveUp(index, index < 1, item)"
        @onMoveDown="onMoveDown(index, index === newExamList.length - 1, item)"
        @onJump="onJump"
      />
      <exam-rich-text
        v-if="item.answerType === 'rich_text'"
        :index="index"
        :item="item"
        :is-need="isNeed"
        :options-list="optionsList"
        @onSave="onSave"
        @onDel="onDel(item.id, item)"
        @onAddExam="type => onAddExam(type, index + 1)"
        @onMoveUp="onMoveUp(index, index < 1, item)"
        @onMoveDown="onMoveDown(index, index === newExamList.length - 1, item)"
        @onJump="onJump"
      />
      <page-end
        v-if="item.answerType === 'page_end'"
        :total-page="totalPageIndex()"
        :current-page-index="currentPageIndex()(item.id)"
        :item="item"
        :is-need="isNeed"
        :options-list="optionsList"
        @onDel="onDel(item.id, item)"
        @onAddExam="type => onAddExam(type, index + 1)"
        @onMoveUp="onMoveUp(index, index < 1, item)"
        @onMoveDown="onMoveDown(index, index === newExamList.length - 1, item)"
        @onJump="onJump"
      />
    </div>
  </div>
</template>

<script>
import { deepClone, setGuid } from '@/utils/util'
import { mapActions } from 'vuex'

export default {
  name: 'ExamList',
  props: {
    examList: {
      type: Array,
      default() {
        return []
      }
    },
    sceneId: {
      type: String,
      default: ''
    },
    categoryType: {
      type: String,
      default: ''
    },
    suitableType: {
      type: String,
      default: ''
    },
    isAttribute: {
      type: Boolean,
      require: false
    },
    isNeed: {
      type: Boolean,
      require: false
    },
    showJump: {
      type: Boolean,
      require: false
    },
    defaultValueType: {
      type: Number,
      require: false
    }
  },
  data() {
    return {
      optionsList: [],
      examType: '',
      newExamList: [],
      replaceExamId: ''
    }
  },
  computed: {
    isEdit() {
      return !!this.sceneId
    }
  },
  watch: {
    examList(newVAl) {
      this.newExamList = deepClone(newVAl)
    }
  },
  mounted() {
    this.getCategoryList()
  },
  methods: {
    ...mapActions(['getVideoUrl', 'getExamDetail', 'onGetCategoryList']),
    getCategoryList() {
      this.onGetCategoryList({ categoryType: 'exam' }).then(res => {
        this.optionsList = res.data
      })
    },
    onTigger(obj, searchType = 0) {
      this.$emit('onTiggerExam', obj, searchType)
    },
    onTiggerTitle(obj) {
      this.$emit('onTitleTiggerExam', obj)
    },
    onSave(obj) {
      if (this.isAttribute)
        return this.$message.error('该活动已分配给医生，题目不支持修改')
      if (obj.save && obj.isInset && obj.hasSet) {
        // 保存编辑时候，对插入题目进行跳题逻辑检查,同时删除活动的整体跳题逻辑
        this.showExamJumpValidModal = true
        this.actionMessage = '当前活动已设置跳题逻辑，若新增题目，将删除该活动的跳题逻辑'
        this.actionExamId = ''
        this.actionFuncBack = function() {
          this.onSave(obj)
        }
        return
      }
      if (obj.save && !obj.examId && obj.answerType !== 'rich_text') {
        this.onTigger(obj, 1)
      }
      if (!obj.save && obj.answerType === 'rich_text') {
        this.replaceExamId = obj.id
        this.$emit('initTemp')
      }
      this.$emit('onSave', obj)
    },
    onDel(id = '') {
      if (this.isEdit && !this.isNeed)
        return this.$message.error('编辑问卷中暂不支持删除问卷')
      if (this.isAttribute)
        return this.$message.error('该活动已分配给医生，题目不支持删除')
      this.examType = ''
      this.$message.success('题目删除成功')
      this.newExamList = [...this.newExamList].filter(v => v.id !== id)
    },
    onMoveUp(index, isTop) {
      if (this.isAttribute)
        return this.$message.error('该活动已分配给医生，题目不支持修改')
      if (isTop) return
      const newlist = [...this.newExamList]
      newlist[index] = newlist.splice(index - 1, 1, newlist[index])[0]
      this.newExamList = newlist
    },
    onMoveDown(index, isEnd) {
      if (this.isAttribute)
        return this.$message.error('该活动已分配给医生，题目不支持修改')
      if (isEnd) return
      const newlist = [...this.newExamList]
      newlist[index] = newlist.splice(index + 1, 1, newlist[index])[0]
      this.newExamList = newlist
    },
    onValidExamJump(call, examId = '') {
      if (!examId) {
        this.actionExamId = ''
        return
      }
    },
    onAddExam(type, index) {
      if (this.isEdit && !this.isNeed)
        return this.$message.error('编辑问卷中暂不支持添加新的问卷')
      if (this.isAttribute)
        return this.$message.error('该活动已分配给医生，题目不支持修改')
      let currentItem = {}
      if (type === 'single' || type === 'multiple' || type === 'sort') {
        currentItem = {
          answerType: type,
          examRichTextContent: '',
          categoryId: '',
          memberTagInfo: [],
          leastAnswerCount: '',
          answers: [{ title: '' }, { title: '' }, { title: '' }, { title: '' }],
          title: '',
          id: setGuid(),
          isRealCanDel: true,
          isSetCorrectAnswer: false,
          save: false
        }
      } else if (type === 'scale') {
        currentItem = {
          answerType: type,
          examRichTextContent: '',
          categoryId: '',
          memberTagInfo: [],
          leastAnswerCount: '',
          scaleQuestionList: '',
          answers: [
            { title: '', orderIndex: '' },
            { title: '', orderIndex: '' },
            { title: '', orderIndex: '' },
            { title: '', orderIndex: '' },
            { title: '', orderIndex: '' }
          ],
          title: '',
          id: setGuid(),
          isRealCanDel: true,
          isSetCorrectAnswer: false,
          save: false
        }
      } else if (type === 'page_end') {
        currentItem = { answerType: type, id: setGuid(), save: false }
      } else if (type === 'rich_text') {
        this.$emit('initTemp')
        currentItem = {
          answerType: type,
          richTextContent: '',
          answers: [],
          title: '',
          id: setGuid(),
          save: false
        }
      } else {
        currentItem = {
          title: '',
          answerType: type,
          examExpand: '',
          examAnswerSettingBO: {
            isInOrder: true,
            isIgnoreCase: true,
            keywordCount: 1
          },
          categoryId: '',
          memberTagInfo: [],
          leastAnswerCount: '',
          answers: [],
          id: setGuid(),
          isRealCanDel: true,
          isSetCorrectAnswer: false,
          save: false
        }
      }
      if (index > 0) {
        // isInset：是否是插入题目
        this.newExamList.splice(index, 0, { ...currentItem, isInset: true })
      } else {
        this.initScrollTop(
          this.$refs.scrollNode.scrollTop,
          this.$refs.scrollNode.scrollHeight
        )
        this.newExamList = [...this.newExamList, currentItem]
      }
      if (type === 'rich_text') {
        this.replaceExamId = currentItem.id
      }
      this.examType = type
    },
    initScrollTop(currentY = 0, targetY = 0) {
      const needScrollTop = targetY - currentY
      let _currentY = currentY
      this.timer = setTimeout(() => {
        const dist = Math.ceil(needScrollTop / 20)
        _currentY += dist
        this.$refs.scrollNode.scrollTop = currentY
        // window.scroll(_currentY, currentY)
        if (needScrollTop > 10 || needScrollTop < -10) {
          this.initScrollTop(_currentY, targetY)
        } else {
          // window.scroll(_currentY, targetY)
          this.$refs.scrollNode.scrollTop = currentY
          this.timer && clearTimeout(this.timer)
        }
      }, 1)
    },
    onShowExamResourceModal(resourceType, item) {
      // 题目资源弹窗
      this.currentExamResourceActionId = item.id
      switch (resourceType) {
        case 1:
          this.isShowExamImageModal = true
          this.currentExamImageList = [...(item.examResourceBOList || [])]
            .filter(v => v.resourceType === 1)
            .map(k => {
              return {
                ...k.resource,
                id: k.id || ''
              }
            })
          break
        case 2:
          this.currentExamResourceActionName = item.title
          this.isShowExamVideoModal = true
          this.currentExamVideoList = [...(item.examResourceBOList || [])]
            .filter(v => v.resourceType === 2)
            .map(k => k.resource)
          break
        default:
          break
      }
    },
    onShowVideoPreviewModal(url, videoId) {
      if (url) {
        this.videoPreviewUrl = url
        this.showVideoPreviewModal = true
      } else if (videoId) {
        this.getVideoUrl(videoId).then(
          res => {
            const resData = res.playUrl || []
            this.videoPreviewUrl = resData.filter(
              v => v.indexOf('.mp4') > -1
            )[0]
            if (!this.videoPreviewUrl)
              return this.$message.error('视频资源加载中，请稍后尝试')
            this.showVideoPreviewModal = true
          },
          rea => {
            this.$message.error(rea)
          }
        )
      }
    },
    totalPageIndex() {
      let totalPageIndex = 0
      let isLastPageIndex = false
      this.newExamList.map((v, i, arr) => {
        if (v.answerType === 'page_end') {
          totalPageIndex++
          isLastPageIndex = i === arr.length - 1
        }
      })
      // 假设最后是一个分页器认为是最后一页
      return isLastPageIndex ? totalPageIndex : totalPageIndex + 1
    },
    currentPageIndex() {
      const currentPageList = [...this.newExamList].filter(
        v => v.answerType === 'page_end'
      )
      let currentIndex = 0
      return currentId => {
        currentPageList.map((c, i) => {
          if (currentId === c.id) {
            currentIndex = i + 1
          }
        })
        return currentIndex
      }
    },
    currentIndex() {
      return currentExamId => {
        let current = 0
        let output = 0
        this.newExamList.map(v => {
          if (v.answerType !== 'page_end' && !v.richTextContent) {
            current++
            if (currentExamId === v.id) {
              output = current
            }
          }
        })
        return output
      }
    },
    onJump() {
      this.$emit('onSetJump')
    }
  }
}
</script>

<style lang="scss" scoped>
.scene-layout {
  background-color: #fff;
  padding-top: 20px;
  //padding-left: 3vw;
  //padding-right: 3vw;
  padding-bottom: 110px;
  overflow-y: auto;
  flex: 1;
}
.scene-exam-wrapper {
  padding-left: 3vw;
  padding-right: 3vw;
  border-bottom: 1px solid #e9eaeb;
  &:hover {
    background: #f6f7fb;
  }
}
</style>
